<template>
  <div id='column'>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-input v-model="selectName" placeholder="请输入栏目名称" clearable></el-input>
        </el-col>
        <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="openDialog(form, true)" type="primary">添加栏目</el-button>
      </el-row>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column label="图标" width="100" class-name="avatar">
          <template #default="scope"><el-avatar :size="40" :src="scope.row.icon"></el-avatar></template>
        </el-table-column>
        <el-table-column prop="icon_class" label="图标样式" width="150"></el-table-column>
        <el-table-column label="Banner" width="150" class-name="avatar">
          <template #default="scope"><el-avatar :size="40" :src="scope.row.banner"></el-avatar></template>
        </el-table-column>
        <el-table-column prop="name" label="名称" width="200"></el-table-column>
        <el-table-column prop="path" label="路由地址" width="200"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{scope.row.status | statusText}}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" width="200">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.sort" @change="changeSort(scope.row)" :controls="false" :min="0" size="small"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template #default="scope">
            <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
            <el-button @click="openDialog(scope.row, false)" type="warning" size="mini">修改</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

      <!-- 添加弹窗 -->
      <el-dialog @close="closeDialog('addColumnItem')" title="添加栏目" :visible.sync="showDialog" class="add_dialog">
        <el-form :model="form" ref="addColumnItem" :rules="rules">
          <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
            <el-col :span="12">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="图标" :label-width="formLabelWidth">
            <el-upload :action="baseURL" :show-file-list="false" :on-success="handleIconSuccess" :before-upload="beforeUpload" class="avatar-uploader">
              <img v-if="form.icon" :src="form.icon" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="图标样式" prop="icon_class" :label-width="formLabelWidth">
            <el-col :span="12">
              <el-input v-model="form.icon_class" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="Banner" :label-width="formLabelWidth">
            <el-upload :action="baseURL" :show-file-list="false" :on-success="handleBannerSuccess" :before-upload="beforeUpload" class="avatar-uploader">
              <img v-if="form.banner" :src="form.banner" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="宽度" prop="width" :label-width="formLabelWidth">
            <el-col :span="12">
              <el-input v-model="form.width" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="高度" prop="height" :label-width="formLabelWidth">
            <el-col :span="12">
              <el-input v-model="form.height" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="路由地址" prop="path" :label-width="formLabelWidth">
            <el-col :span="12">
              <el-input v-model="form.path" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="描述" prop="desc" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{form.status | statusText}}</span>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-col :span="8">
              <el-input-number v-model="form.sort" autocomplete="off" :min="0" :controls="false"></el-input-number>
            </el-col>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button v-if="isAddFlag" @click="addColumn('addColumnItem')" type="primary">确 定</el-button>
          <el-button v-else @click="editItem('addColumnItem')" type="primary">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 查看弹窗 -->
      <el-dialog title="详情" :visible.sync="checkItem" center class="check_dialog">
        <div class="check_div">
          <div class="check_label">名称：</div>
          <div class="check_data">{{checkData.name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">图标：</div>
          <div class="check_data"><el-avatar :src="checkData.icon"></el-avatar></div>
        </div>
        <div class="check_div">
          <div class="check_label">图标样式：</div>
          <div class="check_data">{{checkData.icon_class}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">Banner：</div>
          <div class="check_data"><el-avatar :src="checkData.banner"></el-avatar></div>
        </div>
        <div class="check_div">
          <div class="check_label">路由地址：</div>
          <div class="check_data">{{checkData.path}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">描述：</div>
          <div class="check_data">{{checkData.desc}}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
    import {getColumnList, addColumnItem, getColumnAll, queryColumnItem, editColumnItem, deleteColumnItem} from '@api';
    export default {
        name: 'Column',
        components: {},
        data() {
            return {
                params: { // 请求分页参数
                    page: 1,
                    page_size: 10
                },
                tableData: [], // 表格数据
                loading: true, // 表格加载等待
                showDialog: false, // 是否显示弹窗
                isAddFlag: false, // 是否点击添加用户
                form: { // 添加表单
                    name: '',
                    icon: '',
                    icon_class: '',
                    banner: '',
                    width: '',
                    height: '',
                    banner_change: false,
                    path: '',
                    desc: '',
                    status: 0,
                    sort: 0,
                },
                initForm: {
                    name: '',
                    icon: '',
                    icon_class: '',
                    banner: '',
                    width: '',
                    height: '',
                    banner_change: false,
                    path: '',
                    desc: '',
                    status: 0,
                    sort: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '请输入标题', trigger: 'blur'}
                    ]
                },
                baseURL: `${this.$store.state.config.baseURL}/upload`, // 上传图片地址
                formLabelWidth: '120px', // from表单label的宽度
                total: 0, // 数据总条数
                checkItem: false, // 查看每条的弹窗
                checkData: {}, // 查询每条时的数据
                selectName: '', // 搜索栏目名称
                srcList: [''], // 查看图片
            }
        },
        created() {
            this.getData(this.params);
        },
        filters: {
            statusText(status) {
                if (status > 0) {
                    return '已审核';
                }
                return '未审核';
            }
        },
        methods:{
            search() { // 搜索
                const {selectName} = this;
                this.params.name = selectName;
                this.getData(this.params);
            },
            addColumn(refName) { // 添加
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    this.form.status = parseInt(this.form.status);
                    this.form.width = parseInt(this.form.width);
                    this.form.height = parseInt(this.form.height);
                    addColumnItem(this.form).then(() => {
                        this.showDialog = false;
                        this.getData(this.params);
                        this.$message.success('添加成功!');
                        this.initFormData();
                    });
                });
            },
            queryItem(id) { // 查询单条数据
                this.checkItem = true;
                this.checkData = {};
                queryColumnItem(id).then(res => this.checkData = res.data);
            },
            editItem(refName) { // 修改单条数据
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    const {form, form: {id}} = this;
                    const body = form;
                    body.status = parseInt(body.status);
                    form.width = parseInt(form.width);
                    form.height = parseInt(form.height);
                    body.fields = Object.keys(this.initForm);
                    editColumnItem(id, body).then(() => {
                        this.showDialog = false;
                        this.$message.success('修改成功！');
                        this.getData(this.params);
                    });
                });
            },
            deleteItem(id) { // 删除单挑数据
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteColumnItem(id).then(() => {
                        this.$message.success('删除成功!');
                        this.getData(this.params);
                    });
                }).catch(() => this.$message.info('已取消删除'));
            },
            changeStatus(item) {
                item.fields = ["status"];
                item.status = item.status ? 1 : 0;
                editColumnItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                });
            },
            changeSort(item) {
                item.fields = ["sort"];
                editColumnItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                })
            },
            openDialog(item, isAdd) { // 打开弹窗
                this.form = {...item};
                this.showDialog = true;
                this.isAddFlag = isAdd;
            },
            closeDialog(refName) { // 关闭弹窗
                this.showDialog = false;
                this.initFormData();
                this.$refs[refName].resetFields();
            },
            cancel() {
                this.showDialog = false;
            },
            getSrcList(src) { // 查看图片
                this.srcList = [];
                this.srcList.push(src);
            },
            handleIconSuccess(res, file) { // 上传图片
                this.form.icon = res.data.url;
            },
            handleBannerSuccess(res, file) { // 上传图片
                this.form.banner_change = true;
                this.form.banner = res.data.url;
            },
            beforeUpload(file) { // 上传图片
                const isImg = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isImg) this.$message.error('上传头像图片只能是 JPG/gif/jpeg/png 格式!');
                if (!isLt2M) this.$message.error('上传头像图片大小不能超过 2MB!');
                return isImg && isLt2M;
            },
            handleCurrentChange(val) { // 获取页数
                this.params.page = val;
                this.getData(this.params);
            },
            handleSizeChange(val) { // 获取每页条数
                this.params.page_size = val;
                this.getData(this.params);
            },
            getData(params) {
                this.loading = true;
                getColumnList(params).then(res => {
                    this.loading = false;
                    const {data, count} = res.data;
                    this.total = count;
                    this.tableData = data;
                }).catch(() => {
                    this.loading = false;
                })
            },
            initFormData() { // 初始化数据
                this.form = this.initForm;
            }
        },
    }
</script>

<style lang='less' scoped>

</style>